/* eslint-disable @next/next/no-img-element */
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useForceUpdate } from '@mantine/hooks'
import { useFormContext } from 'react-hook-form'

import { useCollectionDropZone } from '@/shared/hooks'
import { CollectionEditFormFields } from '@/shared/types'
import { cx } from '@/shared/utils'

interface ICollectionBgImageDropzoneProps {
  className?: string
  collectionAddress: string
}

export default function CollectionBgImageDropzone({
  className,
  collectionAddress,
}: ICollectionBgImageDropzoneProps) {
  const { getValues, setValue } = useFormContext<CollectionEditFormFields>()
  const forceUpdate = useForceUpdate()
  const { getRootProps, getInputProps, isDragActive } = useCollectionDropZone({
    onSuccess: url => setValue('backgroundImgUrl', url),
    path: 'background',
    collectionAddress,
  })

  const defaultImgStyle = cx(
    'absolute left-0 top-0 w-full h-full object-cover bg-cover rounded-[6px] hover:brightness-[60%]',
  )

  return (
    <div className={cx('', className)}>
      <div className="text-lg leading-normal font-normal">컬렉션 배경 이미지</div>
      <div
        className={cx(
          'relative mt-[8px] h-[150px] lg:h-[180px] border-borderSecondary border-dashed border-[1px] rounded-[6px] flex flex-col items-center justify-center cursor-pointer hover:bg-bgQuarternary',
          isDragActive ? 'bg-bgQuarternary' : '',
        )}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="px-[10px] text-base leading-normal font-normal text-textSecondary">
          컬렉션 배경으로 사용할 이미지를 선택해주세요.
        </div>
        {getValues('backgroundImgUrl') !== '' && (
          <>
            <img
              src={getValues('backgroundImgUrl')}
              className={cx(
                isDragActive ? cx(defaultImgStyle, 'brightness-[60%]') : defaultImgStyle,
              )}
              alt="collection_background_image"
            />
            <div
              className="z-50 absolute right-[16px] top-[16px] h-[41px] p-[10px] bg-[#3F3F4680] rounded-[8px] cursor-pointer"
              onClick={e => {
                setValue('backgroundImgUrl', '')
                e.stopPropagation()
                forceUpdate()
              }}>
              <XMarkIcon className="w-5 h-5" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

/* eslint-disable @next/next/no-img-element */
import { Space } from '@mantine/core'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { IWhiteListCollection } from '@/shared/types'
import { cx } from '@/shared/utils'

import { ChainWithCategoryTag } from '../search'

interface IWhitelistCollectionCardProps {
  isLast: boolean
  whitelistCollectionItem: IWhiteListCollection
}

const WhitelistCollectionCard = ({
  isLast,
  whitelistCollectionItem,
}: IWhitelistCollectionCardProps) => {
  const { push } = useRouter()
  const handleClickDetailPage = useCallback(() => {
    push(
      ROUTE_PATH.COLLECTION_DETAIL.replace(
        ':collectionAddress',
        whitelistCollectionItem.collectionAddress,
      ),
    )
  }, [push, whitelistCollectionItem.collectionAddress])

  return (
    <div className="w-full flex gap-4" onClick={handleClickDetailPage}>
      <div className="square-item aspect-square overflow-hidden shrink-0 rounded-md bg-appBgTertiary">
        <img
          alt="white list collection image"
          className="object-cover w-full h-full"
          src={whitelistCollectionItem.imgUrl ?? '/img/placeholder/item-card-replace.png'}
          onError={e => {
            e.currentTarget.src = '/img/placeholder/item-card-replace.png'
          }}
        />
      </div>

      <div className={cx('w-full pb-2', !isLast ? 'border-b border-b-appBorderQuarternary' : '')}>
        <ChainWithCategoryTag
          chainId={whitelistCollectionItem.chainId}
          categoryTitle={whitelistCollectionItem.categoryId}
        />

        <Space h={8} />

        <div className="flex gap-1 items-center">
          <p className="w-fit text-base font-medium leading-[20px] text-appTextPrimary line-clamp-2 text-ellipsis">
            {whitelistCollectionItem.title}
          </p>
          <span className="shrink-0">
            {whitelistCollectionItem.isOriginals && <VerifiedBadge size="sm" />}
          </span>
        </div>
      </div>
    </div>
  )
}

export default WhitelistCollectionCard

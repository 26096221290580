import { Space } from '@mantine/core'
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Fragment, Suspense, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { RefreshButton } from '@/shared/assets'
import { TAB_BAR_THEME } from '@/shared/constants'
import { useCategoryTabList, useIntersectionObserver } from '@/shared/hooks'
import {
  useGetCollectionCategories,
  useGetCollectionListInfinityQuery,
} from '@/shared/services/collections'
import { IGetCollectionListResponse } from '@/shared/types'

import { CommonErrorFallback } from '../common'
import TabBar, { ITabData } from '../common/TabBar'
import WhitelistCollectionCard from './WhitelistCollectionCard'

interface IWhitelistCollectionListDesignProps {
  currentTab?: string
  hasNextPage?: boolean
  tabList?: ITabData<string>[] | null
  targetRef: React.RefObject<HTMLDivElement>
  data?: InfiniteData<IGetCollectionListResponse>
  refetchGetCollectionList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<InfiniteData<IGetCollectionListResponse>, unknown>>
}

const WhitelistCollectionList = () => {
  const { pathname } = useRouter()
  const targetRef = useRef<HTMLDivElement>(null)

  const { data: collectionCategoryData } = useGetCollectionCategories(pathname)
  const { tabList, currentTab } = useCategoryTabList({ data: collectionCategoryData })

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchGetCollectionList,
  } = useGetCollectionListInfinityQuery({
    pathname,
    params: {
      categoryId: currentTab,
    },
  })

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  return (
    <>
      <WhitelistCollectionList.Design
        data={data}
        tabList={tabList}
        targetRef={targetRef}
        currentTab={currentTab}
        hasNextPage={hasNextPage}
        refetchGetCollectionList={refetchGetCollectionList}
      />
    </>
  )
}

const WhitelistCollectionListDesign = ({
  data,
  tabList,
  targetRef,
  currentTab,
  hasNextPage,
  refetchGetCollectionList,
}: IWhitelistCollectionListDesignProps) => {
  const { t } = useTranslation(['common', 'web-collection'])
  if (tabList && tabList.length === 0) {
    return (
      <section className="px-4 flex justify-center items-center mt-[150px]">
        <h1 className="text-sm font-normal leading-[21px] text-appTextTertiary">
          {t('common:common.empty-collection-display')}
        </h1>
      </section>
    )
  }

  const totalCount = data?.pages[0].totalCount ?? 0

  if (totalCount === 0) {
    return (
      <section>
        <TabBar theme={TAB_BAR_THEME.handler} tabData={tabList} selectedTab={currentTab} />

        <div className="flex justify-center items-center mt-[150px]">
          <h1 className="text-sm font-normal leading-[21px] text-appTextTertiary">
            {t('common:common.empty-category-collection')}
          </h1>
        </div>
      </section>
    )
  }

  return (
    <>
      <div className="sticky top-0 left-0 right-0 bg-bgPrimary">
        <TabBar theme={TAB_BAR_THEME.handler} tabData={tabList} selectedTab={currentTab} />
      </div>

      <div className="px-4">
        <div className="flex items-center gap-x-1">
          <RefreshButton
            size="sm"
            theme="white"
            onClick={() => {
              refetchGetCollectionList()
              toast(t('common:common.update-requested'))
            }}
          />
          <p className="text-sm font-medium text-appTextPrimary leading-[18px]">
            {t('common:common.label-collection-number').replace(
              '{value}',
              totalCount.toString() ?? '0',
            )}
          </p>
        </div>

        <Space h={24} />

        <div className="flex flex-col gap-4">
          {data?.pages?.map(({ collections }, i) => {
            const lastIndex = collections.length - 1

            return (
              <Fragment key={`whitelist-collection-wrapper-${i}-${currentTab}`}>
                {collections.map((collection, index) => (
                  <WhitelistCollectionCard
                    whitelistCollectionItem={collection}
                    key={`whitelist-collection-${collection.title}`}
                    isLast={lastIndex === index && !hasNextPage}
                  />
                ))}
              </Fragment>
            )
          })}
          {hasNextPage && (
            <div ref={targetRef} className="flex flex-col gap-4">
              {range(5).map(index => (
                <div
                  className="w-full flex gap-4"
                  key={`whitelist-collection-card-skeleton-${index}`}>
                  <div className="square-item overflow-hidden shrink-0 rounded-md bg-appBgQuaternary">
                    <div className="aspect-square bg-appBgQuaternary" />
                  </div>

                  <div className="flex flex-col justify-between">
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="w-[50px] h-[23px] rounded-[2px] bg-appBgQuaternary" />
                      <div className="w-[200px] h-[20px] rounded-[2px] bg-appBgQuaternary" />
                    </div>

                    <div className="w-full h-[1px] bg-appBgQuaternary" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Space h={100} />

        <div className="w-full h-[250px] flex flex-col items-center justify-center rounded-md bg-appBgSecondary">
          <h1 className="text-lg font-medium leading-[23px] text-appTextPrimary whitespace-pre-wrap text-center">
            {t('web-collection:collection.request-title')}
          </h1>
          <Space h={8} />
          <h1 className="text-sm text-appTextTertiary font-normal text-center px-[46px] whitespace-pre-wrap">
            {t('web-collection:collection.request-description')}
          </h1>
          <Space h={20} />
          <button
            className="w-auto h-[44px] bg-appBgQuaternary px-5 rounded "
            onClick={() => {
              if (!window) return
              window.location.href = 'https://modernlion.typeform.com/collecion'
            }}>
            <p className="text-sm font-medium leading-[18px]">
              {t('web-collection:collection.request-cta-label')}
            </p>
          </button>
        </div>
      </div>

      <Space h={100} />
    </>
  )
}

const WhitelistCollectionListSkeleton = () => {
  return (
    <>
      <Space h={16} />
      <TabBar.Skeleton />
      <Space h={24} />
      <div className="w-[100px] h-[20px] rounded-[2px] bg-appBgQuaternary ml-4" />
      <Space h={24} />
      <section className="flex flex-col gap-4 px-4">
        {range(5).map(index => (
          <div className="w-full flex gap-4" key={`whitelist-collection-card-skeleton-${index}`}>
            <div className="square-item overflow-hidden shrink-0 rounded-md bg-appBgQuaternary">
              <div className="aspect-square bg-appBgQuaternary" />
            </div>

            <div className="flex flex-col justify-between">
              <div className="w-full flex flex-col gap-y-2">
                <div className="w-[50px] h-[23px] rounded-[2px] bg-appBgQuaternary" />
                <div className="w-[200px] h-[20px] rounded-[2px] bg-appBgQuaternary" />
              </div>

              <div className="w-full h-[1px] bg-appBgQuaternary" />
            </div>
          </div>
        ))}
      </section>
    </>
  )
}

const WhitelistCollectionListErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Space h={86} />
      <CommonErrorFallback
        error={error}
        bgColor="bg-bgPrimary"
        handleRetry={resetErrorBoundary}
        title={t('common.error-data')}
      />
    </>
  )
}

const WhitelistCollectionListWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={WhitelistCollectionList.ErrorFallback} onReset={reset}>
      <Suspense fallback={<WhitelistCollectionList.Skeleton />}>
        <WhitelistCollectionList />
      </Suspense>
    </ErrorBoundary>
  )
}

export default WhitelistCollectionList

WhitelistCollectionList.Design = WhitelistCollectionListDesign
WhitelistCollectionList.Skeleton = WhitelistCollectionListSkeleton
WhitelistCollectionList.ErrorFallback = WhitelistCollectionListErrorFallback
WhitelistCollectionList.WithErrorBoundary = WhitelistCollectionListWithErrorBoundary

/* eslint-disable @next/next/no-img-element */
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import bigDecimal from 'js-big-decimal'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { useBalance, useBreakpoint, useIntersectionObserver } from '@/shared/hooks'
import {
  useGetCollectionPayout,
  useGetCollectionPayoutHistories,
} from '@/shared/services/collections'
import { IGetCollectionResponse } from '@/shared/types'
import {
  convertBalanceToCurrencyWithSymbol,
  getChainscanUrl,
  optimizeImage,
  timeFormatter,
} from '@/shared/utils'

import { PriceLabel } from '../common'
import { NotEnabled } from '../common/error'
import { Spinner } from '../core-ui'

interface ICollectionPayoutListProps {
  collection: IGetCollectionResponse
  isOwner: boolean
}

function CollectionPayoutList({ collection, isOwner }: ICollectionPayoutListProps) {
  const { isLG } = useBreakpoint()
  const { query, pathname } = useRouter()
  const { collectionAddress } = query as { collectionAddress?: string }

  const { allCoinPrice: coinPrice, currency } = useBalance()

  const targetRef = useRef<HTMLDivElement>(null)

  const {
    data: payoutInfo,
    status: payoutInfoStatus,
    refetch: refetchPayout,
  } = useGetCollectionPayout(pathname, isOwner, collectionAddress)

  const {
    data: payoutHistory,
    status: payoutHistoryStatus,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: refetchPayoutHistory,
  } = useGetCollectionPayoutHistories(pathname, isOwner, collectionAddress)

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  useEffect(() => {
    if (isOwner) {
      refetchPayout()
      refetchPayoutHistory()
    }
  }, [isOwner, refetchPayout, refetchPayoutHistory])

  if (
    payoutInfoStatus === 'loading' ||
    payoutHistoryStatus === 'loading' ||
    payoutHistory === undefined ||
    payoutInfo === undefined
  )
    return (
      <div className="min-h-[100vh] flex align-center justify-center">
        <Spinner />
      </div>
    )

  if (payoutInfoStatus === 'error' || payoutHistoryStatus === 'error') return <NotEnabled />

  return (
    <div className="max-w-[1280px] mx-auto flex flex-col justify-center">
      <div className=" w-full flex flex-col gap-4 lg:gap-6 mb-4 lg:mb-6 mt-[55px] px-4 lg:px-6">
        <div className="w-full leading-[46px] text-2xl md:text-[36px] break-all font-extrabold">
          {collection.title || collectionAddress}
        </div>
        <div className="w-full flex gap-6">
          <div>
            <div className="flex gap-1 items-center">
              <PriceLabel
                size={isLG ? 'xl' : 'sm'}
                paymentType={payoutInfo.data.paymentType}
                price={Number(payoutInfo.data.profit)}
              />
            </div>
            <div className="text-textSecondary text-sm leading-normal font-normal">
              {payoutInfo.data.paymentType} 총 수익
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-4 lg:p-6 overflow-x-scroll scrollbar-hide">
        <div className="min-w-[976px] lg:min-w-[1232px] w-full py-5 text-base text-textSecondary font-Pretendard font-semibold leading-normal flex border border-l-0 border-r-0 border-t-0 border-b-1 border-borderQuarternary">
          <div className="pl-5 w-[216px] lg:w-[472px]">아이템</div>
          <div className="pl-5 w-[100px]">화폐</div>
          <div className="pl-5 w-[100px]">수수료</div>
          <div className="pl-5 w-[160px]">거래 가격</div>
          <div className="pl-5 w-[160px]">실수령액</div>
          <div className="pl-5 w-[240px]">거래일</div>
        </div>
        {payoutHistoryStatus === 'success' && payoutHistory?.pages[0].totalCount === 0 && (
          <div className="w-full text-center mt-[100px] mx-auto fixed">아직 거래내역이 없어요.</div>
        )}
        {payoutHistoryStatus === 'success' &&
          payoutHistory.pages.map(({ histories }) => {
            return histories.map((history, index) => {
              return (
                <div key={`payout-history-${index}`}>
                  <div className="flex min-w-[976px] lg:min-w-[1232px] w-full py-3 font-Pretendard leading-normal">
                    <div className="pl-5 w-[216px] lg:w-[472px] flex gap-[10px]">
                      <img
                        src={optimizeImage(history.mediaUrl, 56)}
                        className="bg-[#71717A] rounded-lg min-w-[56px] min-h-[56px] w-[56px] h-[56px] object-contain"
                        onError={e => {
                          e.currentTarget.src = '/img/placeholder/item-card-replace.png'
                        }}
                        alt="item_thumbnail"
                      />
                      <span className="flex items-center text-base text-textPrimary font-Pretendard font-semibold leading-normal ">
                        {history.title}
                      </span>
                    </div>
                    <div className="pl-5 w-[100px]">
                      <span className="flex h-full items-center text-base text-textSecondary font-Pretendard font-normal leading-normal">
                        {history.paymentType}
                      </span>
                    </div>
                    <div className="pl-5 w-[100px]">
                      <span className="flex h-full items-center text-base text-textSecondary font-Pretendard font-normal leading-normal">
                        {bigDecimal.multiply(history.rate, 100)}%
                      </span>
                    </div>
                    <div className="pl-5 w-[160px] flex flex-col gap-1 justify-center">
                      <div className="flex gap-[2px] items-baseline">
                        <PriceLabel
                          size="xl"
                          paymentType={history.paymentType}
                          price={Number(history.price)}
                        />
                      </div>
                      <span>
                        (
                        {convertBalanceToCurrencyWithSymbol({
                          coinPrice,
                          price: history.price,
                          paymentType: history.paymentType,
                          currency,
                        })}
                        )
                      </span>
                    </div>
                    <div className="pl-5 w-[160px] flex flex-col gap-1 justify-center">
                      <div className="flex gap-[2px] items-baseline">
                        <PriceLabel
                          size="xl"
                          paymentType={history.paymentType}
                          price={Number(history.realAmount)}
                        />
                      </div>
                      <span>
                        (
                        {convertBalanceToCurrencyWithSymbol({
                          coinPrice,
                          price: history.realAmount,
                          paymentType: history.paymentType,
                          currency,
                        })}
                        )
                      </span>
                    </div>
                    <div className="pl-5 w-[240px] flex flex-col justify-center text-textTertiary">
                      <div className="flex gap-[10px]">
                        <span className="text-sm">
                          {timeFormatter(history.occurredAt, 'YYYY.MM.DD A h:mm')}
                        </span>
                        <ArrowTopRightOnSquareIcon
                          className="w-4 h-full cursor-pointer"
                          onClick={() => {
                            if (window !== undefined)
                              window.location.href = `${getChainscanUrl(collection.chainId)}/tx/${
                                history.transactionHash
                              }`
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          })}

        {hasNextPage && (
          <>
            <div className="w-full" ref={targetRef}>
              <Spinner />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CollectionPayoutList

import { useQueryClient } from '@tanstack/react-query'
import bigDecimal from 'js-big-decimal'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { FormProvider, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useCollectionEditForm } from '@/shared/hooks'
import { useCollectionEditMutation } from '@/shared/services/collections'
import {
  CollectionEditFormFields,
  IConvertedCollectionForEdit,
  IGetCollectionResponse,
} from '@/shared/types'

import { Button, Textarea, TextInput } from '../core-ui'
import CollectionBgImageDropzone from './CollectionBgImageDropzone'
import CollectionProfileImageDropzone from './CollectionProfileImageDropzone'
import CollectionRoyaltyFields from './CollectionRoyaltyFields'

const CollectionEditForm = ({
  collection,
  collectionAddress,
}: {
  collection: IConvertedCollectionForEdit
  collectionAddress: string
}) => {
  const { pathname, back } = useRouter()
  const queryClient = useQueryClient()

  const { mutate: collectionEdit } = useCollectionEditMutation()

  const method = useCollectionEditForm({ collection })

  const { handleSubmit, register } = method

  const onSubmit: SubmitHandler<CollectionEditFormFields> = useCallback(
    ({ collectionRoyalties, ...formData }) => {
      if (collectionAddress === undefined) return
      const royalty = collectionRoyalties.map(item => ({
        accountAddress: item.accountAddress,
        rate: Number(bigDecimal.multiply(item.percentage, '0.01')),
        chainId: collection.chainId,
        collectionAddress: collection.collectionAddress,
      }))
      collectionEdit(
        {
          collectionAddress,
          body: { ...formData, royalty },
        },
        {
          onSuccess: async () => {
            queryClient.refetchQueries<IGetCollectionResponse>([
              pathname,
              'getCollection',
              { collectionAddress },
            ])
            back()
            if (toast.isActive('collection-edit-submit-success')) return
            toast('변경 사항을 저장했어요.', {
              toastId: 'collection-edit-submit-success',
            })
          },
          onError: () => {
            if (toast.isActive('collection-edit-submit-error')) return
            toast('변경이 저장되지 않았어요. 다시 시도해주세요.', {
              toastId: 'collection-edit-submit-error',
            })
          },
        },
      )
    },
    [
      back,
      collection.chainId,
      collection.collectionAddress,
      collectionAddress,
      collectionEdit,
      pathname,
      queryClient,
    ],
  )

  if (collectionAddress === undefined) return <></>

  return (
    <FormProvider {...method}>
      <div className="px-[16px] pb-[170px] max-w-[540px] mx-auto">
        <form onSubmit={e => e.preventDefault()}>
          <div className="mt-[48px]">
            <div className="text-2xl lg:text-4xl leading-tight font-semibold">컬렉션 수정</div>
            <div className="mt-[12px] text-sm lg:text-base leading-normal font-normal text-textSecondary">
              컬렉션 기본 정보와 크리에이터 수수료 정보를 수정할 수 있어요.
            </div>
          </div>
          <div className="mt-[80px]">
            <div className="text-lg lg:text-2xl leading-normal lg:leading-tight font-medium">
              크리에이터 수수료 정보
            </div>
            <div className="mt-[12px] text-sm lg:text-base leading-normal font-normal text-textSecondary">
              컬렉션 창작에 참여한 크리에이터들의 지갑 주소와 수수료 비율을 입력해주세요.
            </div>
          </div>
          <CollectionRoyaltyFields />
          <div className="mt-[120px]">
            <div className="text-2xl leading-tight font-medium">기본 정보</div>
            <div className="mt-[12px] text-base leading-normal font-normal text-textSecondary">
              컬렉션 페이지에 보여질 기본정보를 입력해주세요.
            </div>
            <CollectionProfileImageDropzone
              className="mt-[40px]"
              collectionAddress={collectionAddress}
            />
            <CollectionBgImageDropzone
              className="mt-[40px]"
              collectionAddress={collectionAddress}
            />
            <div className="mt-[40px] flex flex-col gap-y-[8px]">
              <div className="text-lg leading-normal font-normal">컬렉션 설명</div>
              <Textarea {...register('description')} />
            </div>
            <div className="mt-[40px] gap-y-[8px] flex flex-col">
              <div className="text-lg leading-normal font-normal">외부 링크</div>
              <TextInput
                leftIconPath="/img/icon/globe.svg"
                placeholder="https://yourproject.com"
                className="w-[320px]"
                {...register('website')}
              />
              <TextInput
                leftIconPath="/img/icon/discord-light.svg"
                placeholder="https://discord.gg/yourproject"
                className="w-[320px]"
                {...register('discord')}
              />
              <TextInput
                leftIconPath="/img/icon/twitter-light.svg"
                placeholder="https://twitter.com/yourproject"
                className="w-[320px]"
                {...register('twitter')}
              />
            </div>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              text="저장하기"
              theme="Accent"
              size="sm"
              color="text-black"
              className="mt-[80px] w-[500px] hidden lg:block"
            />
            <div className="lg:hidden fixed bg-bgSecondary left-0 bottom-0 p-[16px] h-[73px] w-screen z-50">
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                text="저장하기"
                theme="Accent"
                size="sm"
                color="text-black"
              />
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  )
}

export default CollectionEditForm

import { XMarkIcon } from '@heroicons/react/24/solid'
import { ethers } from 'ethers'
import { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { CollectionEditFormFields } from '@/shared/types'

import { TextInput } from '../core-ui'

export default function CollectionRoyaltyFields() {
  const {
    formState: { errors },
    control,
    clearErrors,
  } = useFormContext<CollectionEditFormFields>()

  const { fields, append, remove } = useFieldArray<CollectionEditFormFields>({
    name: 'collectionRoyalties',
  })

  const isDuplicated = useCallback(
    (value: string, index: number) => {
      return fields.some((field, idx) => idx !== index && field.accountAddress === value)
    },
    [fields],
  )

  const isAddressValid = useCallback((value: string) => {
    return ethers.utils.isAddress(value)
  }, [])

  const isTotalPercentageValid = useCallback(
    (value: string) => {
      return (
        fields.reduce((total, field) => total + Number(field.percentage), 0) + Number(value) <= 20
      )
    },
    [fields],
  )

  const isPercentageError = useMemo(
    () =>
      Object.values(errors?.collectionRoyalties ?? {}).some(
        (fieldError: any) => fieldError?.percentage,
      ),
    [errors?.collectionRoyalties],
  )

  const percentageErrorMessage = useMemo(
    () =>
      Object.values(errors?.collectionRoyalties ?? {}).map((fieldError: any) => {
        return fieldError?.percentage?.message
      }),
    [errors?.collectionRoyalties],
  )

  const handlePercentageChange = useCallback(
    (onChange: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/[^0-9.]/g, '')
      onChange(value)
    },
    [],
  )

  return (
    <div className="mt-[24px]">
      {fields.map((field, index) => {
        return (
          <div key={`royalty-input-group-${field.id}`} className="flex gap-[8px]">
            <Controller
              render={({ field }) => (
                <TextInput
                  placeholder="지갑 주소를 입력해주세요."
                  isError={errors?.collectionRoyalties?.[index]?.accountAddress !== undefined}
                  helperText={errors?.collectionRoyalties?.[index]?.accountAddress?.message}
                  className={
                    errors?.collectionRoyalties?.[index]?.accountAddress !== undefined
                      ? 'flex-1 w-full'
                      : 'flex-1 w-full mb-[10px]'
                  }
                  {...field}
                />
              )}
              name={`collectionRoyalties.${index}.accountAddress` as const}
              control={control}
              defaultValue={field.accountAddress}
              rules={{
                required: '주소를 입력하지 않았어요.',
                validate: {
                  duplicated: value => !isDuplicated(value, index) || '동일한 주소를 입력했어요.',
                  sameAddress: value => isAddressValid(value) || '잘못된 지갑 주소를 입력했어요.',
                },
              }}
            />
            <Controller
              render={({ field }) => (
                <div className="w-[88px]">
                  <TextInput
                    placeholder="예 2.5"
                    isError={errors?.collectionRoyalties?.[index]?.percentage !== undefined}
                    {...field}
                    onChange={handlePercentageChange(field.onChange)}
                    rightElement={<span className="text-sm lg:text-base">%</span>}
                  />
                </div>
              )}
              name={`collectionRoyalties.${index}.percentage` as const}
              control={control}
              defaultValue={field.percentage}
              rules={{
                required: '수수료 비율을 입력하지 않았어요.',
                validate: {
                  validNumber: value => {
                    if (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 100) {
                      return '숫자만 입력해주세요.'
                    }
                    return true
                  },
                  totalPercentage: value => {
                    if (!isTotalPercentageValid(value)) {
                      return '수수료 비율은 합하여 최대 20%까지만 가능해요.'
                    }
                    return true
                  },
                },
              }}
            />

            <div
              className="h-[41px] p-[10px] border-borderTertiary border-[1px] rounded-[6px] border-solid cursor-pointer"
              onClick={() => {
                remove(index)
                clearErrors('collectionRoyalties')
              }}>
              <XMarkIcon className="w-5 h-5" />
            </div>
          </div>
        )
      })}
      {isPercentageError && (
        <div className="mt-2 text-sm lg:text-base text-red-500">{percentageErrorMessage[0]}</div>
      )}
      <button
        type="button"
        disabled={fields.length >= 5 || errors.collectionRoyalties !== undefined}
        className="w-full mt-[12px] py-[10px] px-[12px] bg-bgQuarternary rounded-[4px] flex justify-center items-center disabled:opacity-30"
        onClick={() => append({ accountAddress: '', rate: '', percentage: '' })}>
        <div>크리에이터 주소 추가</div>
      </button>
    </div>
  )
}

/* eslint-disable @next/next/no-img-element */
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useForceUpdate, useId } from '@mantine/hooks'
import { useFormContext } from 'react-hook-form'

import { useCollectionDropZone } from '@/shared/hooks'
import { CollectionEditFormFields } from '@/shared/types'
import { cx } from '@/shared/utils'

interface ICollectionProfileImageDropzoneProps {
  className: string
  collectionAddress: string
}

export default function CollectionProfileImageDropzone({
  className,
  collectionAddress,
}: ICollectionProfileImageDropzoneProps) {
  const { getValues, setValue } = useFormContext<CollectionEditFormFields>()
  const forceUpdate = useForceUpdate()
  const { getRootProps, getInputProps, isDragActive } = useCollectionDropZone({
    onSuccess: url => setValue('imgUrl', url),
    path: 'main',
    collectionAddress,
  })
  const uuid = useId()

  const defaultHoverTextStyle = cx(
    'group-hover:block absolute w-full left-0 top-[50%] translate-y-[-50%] text-sm leading-normal font-normal text-center z-30 cursor-pointer',
  )

  const defaultImgStyle = cx(
    'w-full h-full bg-zinc-700 rounded-full object-cover bg-cover group-hover:brightness-[60%] z-10',
  )

  return (
    <div className={cx('', className)}>
      <div className="text-lg leading-normal font-normal">컬렉션 프로필 이미지</div>
      <div className="mt-[12px] text-base leading-normal font-normal text-textSecondary">
        아래 이미지 영역을 클릭하여 프로필 사진으로 사용할 파일을 선택해주세요.
      </div>
      <div className="relative mt-[16px] w-[120px] h-[120px]">
        <div
          id={uuid}
          className={cx(
            'group relative w-full h-full bg-zinc-700 rounded-full cursor-pointer z-30',
          )}
          {...getRootProps()}>
          <input {...getInputProps()} />
          <label
            htmlFor={uuid}
            className={cx(
              isDragActive ? defaultHoverTextStyle : cx('hidden', defaultHoverTextStyle),
            )}>
            이미지 선택
          </label>
          {getValues('imgUrl') !== '' && (
            <>
              <img
                src={getValues('imgUrl')}
                className={isDragActive ? cx(defaultImgStyle, 'brightness-[60%]') : defaultImgStyle}
                alt="collection_profile_image"
              />
            </>
          )}
        </div>
        {getValues('imgUrl') !== '' && (
          <div
            className="z-[50] absolute right-0 bottom-0 p-[6px] bg-bgQuarternary rounded-full cursor-pointer"
            onClick={e => {
              setValue('imgUrl', '')
              e.stopPropagation()
              forceUpdate()
            }}>
            <XMarkIcon className="w-5 h-5" />
          </div>
        )}
      </div>
    </div>
  )
}
